import React from 'react';
import values from 'lodash/values';
import { LocationsGrid } from '@skunexus/warehouse-locations';
import { withTabs } from '@skunexus/component-catalog';
import { filterAccessibleItems } from '../../../utils';

const mapStateToProps = (props) => {
    return {
        ...props,
        formPageRoute: '/settings/warehouses/warehouses-form',
        locationsButtonOnClick: (id) => {
            const routeIdParam = LocationsGrid.routeIdParam;
            const path = `/settings/warehouses/warehouse/${routeIdParam}/locations`;
            props.history.push(path.replace(routeIdParam, id));
        }
    };
};

export default app => ({
    warehouses: {
        path: app.settingsWarehousesTabs.getItem('warehouses').link,
        title: app.settingsWarehousesTabs.getItem('warehouses').title,
        permission: app.settingsWarehousesTabs.getItem('warehouses').permission,
        props: () => { },
        loader: () => import('@skunexus/warehouses-grid'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsWarehousesTabs.getAll())), { sub: true })(
                    (props) => (
                        <Component {...mapStateToProps(props)} />
                    )
                )
            )
        ),
    },
    warehouseForm: {
        path: '/settings/warehouses/warehouses-form/:id?',
        title: 'Warehouses Form',
        props: () => (
            {
                indexPageRoute: '/settings/warehouses/warehouses',
                detailsPageRoute: '/settings/warehouses/warehouses-dt-form'
            }
        ),
        loader: () => import('@skunexus/warehouse-form'),
    },
    warehouseLocations: {
        path: `/settings/warehouses/warehouse/${LocationsGrid.routeIdParam}/locations`,
        title: 'Warehouse > Locations',
        permission: ['warehouses.index'],
        props: () => ({}),
        loader: () => import('@skunexus/warehouse-locations'),
    },
    warehouseDtForm: {
        path: '/settings/warehouses/warehouses-dt-form/:id',
        title: 'Warehouses Form - Description Templates',
        props: () => (
            {
                indexPageRoute: '/settings/warehouses/warehouses',
                detailsPageRoute: '/settings/warehouses/warehouses' //there is none so we redirect to index page
            }
        ),
        loader: () => import('@skunexus/warehouse-dt-form'),
    },
    descriptors: {
        path: app.settingsWarehousesTabs.getItem('descriptors').link,
        title: app.settingsWarehousesTabs.getItem('descriptors').title,
        permission: app.settingsWarehousesTabs.getItem('descriptors').permission,
        props: () => (
            {
                formPageRoute: '/settings/warehouses/descriptors-form'
            }
        ),
        loader: () => import('@skunexus/descriptors-grid'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsWarehousesTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    descriptorsForm: {
        path: '/settings/warehouses/descriptors-form/:id?',
        title: 'Descriptor Form',
        props: () => (
            {
                indexPageRoute: '/settings/warehouses/descriptors',
                detailsPageRoute: '/settings/warehouses/descriptors' //there is none so we redirect to index page
            }
        ),
        loader: () => import('@skunexus/descriptors-form'),
    },
    descriptorTemplates: {
        path: app.settingsWarehousesTabs.getItem('descriptorTemplates').link,
        title: app.settingsWarehousesTabs.getItem('descriptorTemplates').title,
        permission: app.settingsWarehousesTabs.getItem('descriptorTemplates').permission,
        props: () => (
            {
                formPageRoute: '/settings/warehouses/descriptor-templates-form'
            }
        ),
        loader: () => import('@skunexus/description-templates-grid'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsWarehousesTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    descriptorTemplatesForm: {
        path: '/settings/warehouses/descriptor-templates-form/:id?',
        title: 'Descriptor Templates Form',
        props: () => (
            {
                indexPageRoute: '/settings/warehouses/descriptor-templates',
                detailsPageRoute: '/settings/warehouses/descriptor-templates-d-form'
            }
        ),
        loader: () => import('@skunexus/description-templates-form'),
    },
    descriptorTemplatesDForm: {
        path: '/settings/warehouses/descriptor-templates-d-form/:id',
        title: 'Descriptor Templates Form - Descriptors',
        props: () => (
            {
                indexPageRoute: '/settings/warehouses/descriptor-templates',
                detailsPageRoute: '/settings/warehouses/descriptor-templates' //there is none so we redirect to index page
            }
        ),
        loader: () => import('@skunexus/description-templates-d-form'),
    },
    warehouseConfigurations: {
        path: app.settingsWarehousesTabs.getItem('configuration').link,
        title: app.settingsWarehousesTabs.getItem('configuration').title,
        permission: app.settingsWarehousesTabs.getItem('configuration').permission,
        props: () => (
            {
                formPageRoute: '/settings/warehouses/warehouse-cf-form'
            }
        ),
        loader: () => import('@skunexus/warehouses-configuration'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsWarehousesTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    warehouseConfigurationForm: {
        path: '/settings/warehouses/warehouse-cf-form/:id?',
        title: 'Configuration Form',
        props: () => (
            {
                indexPageRoute: '/settings/warehouses/warehouse-configurations',
                detailsPageRoute: '/settings/warehouses/warehouse-configurations' //there is none so we redirect to index page
            }
        ),
        loader: () => import('@skunexus/warehouse-cf-form'),
    },
    carts: {
        path: app.settingsWarehousesTabs.getItem('carts').link,
        title: app.settingsWarehousesTabs.getItem('carts').title,
        permission: app.settingsWarehousesTabs.getItem('carts').permission,
        props: () => (
            {
                formPageRoute: '/settings/warehouses/carts-form'
            }
        ),
        loader: () => import('@skunexus/carts-grid'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsWarehousesTabs.getAll())), { sub: true })(Component)
            )
        ),
    },
    cartsForm: {
        path: '/settings/warehouses/carts-form/:id?',
        title: 'Carts Form',
        props: () => (
            {
                indexPageRoute: '/settings/warehouses/carts',
                detailsPageRoute: '/settings/warehouses/carts' //there is none so we redirect to index page
            }
        ),
        loader: () => import('@skunexus/carts-form'),
    }
});