
export default app => ({
    main: {
        id: 'Dashboard',
        label: 'Dashboard',
        title: 'Fulfillments > Pickup',
        link: '/fulfillment-pickup/main',
        permission: 'fulfillments.index',
        order: 10
    },
    pick: {
        id: 'Pick',
        label: 'Pick',
        title: 'Fulfillments > Pickup > Pick',
        link: '/fulfillment-pickup/pick',
        permission: 'fulfillments.picking',
        order: 20
    },
    groupPick: {
        id: 'Group Pick',
        label: 'Group Pick',
        title: 'Fulfillments > Pickup > Group Pick',
        link: '/fulfillment-pickup/group-pick',
        permission: 'fulfillments.picking',
        order: 30
    },
    pack: {
        id: 'Pack',
        label: 'Pack',
        title: 'Fulfillments > Pickup > Pack',
        link: '/fulfillment-pickup/pack',
        permission: 'fulfillments.packing',
        order: 40
    },
    ready: {
        id: 'Ready For Pickup',
        label: 'Ready For Pickup',
        title: 'Fulfillments > Pickup > Ready For Pickup',
        link: '/fulfillment-pickup/ready',
        permission: 'fulfillments.index',
        order: 50
    },
    hospital: {
        id: 'Hospital',
        label: 'Hospital',
        title: 'Fulfillments > Pickup > Hospital',
        link: '/fulfillment-pickup/hospital',
        permission: 'fulfillments.hospital',
        order: 60
    },
    finalized: {
        id: 'Finalized',
        label: 'Finalized',
        title: 'Fulfillments > Pickup > Finalized',
        permission: 'fulfillments.index',
        link: '/fulfillment-pickup/finalized',
        order: 70
    }
})