import createConfigurableObject from '../../../utils/configurationObject';

export default app => {
    app.routes = app.routes || createConfigurableObject();
    app.routes.addItem('dashboard', {
        exact: true,
        path: '/:path(|dashboard)',
        title: 'Dashboard',
        loader: () => import('@skunexus/dashboard'),
    });
}