import React from 'react';
import { ParentSpaceProvider, SubspaceProvider } from 'react-redux-subspace';
import values from 'lodash/values';
import MainTabs from '@skunexus/fulfillment-main-tabs';

import app from '../../../../config';
import { firstAllowedLink, filterAccessibleItems, combinePermissions } from '../../../../utils';


const MainTabsLayout = ({ children, ...props }) => {

  const states = () => {
    const fulfillmentShipTabs = app.fulfillmentShipTabs.getAll();
  
    if (!!fulfillmentShipTabs.waves) {
      const fulfillmentWavesTabs = values(app.fulfillmentWavesTabs.getAll());
  
      fulfillmentShipTabs.waves.link = firstAllowedLink(fulfillmentWavesTabs);
      fulfillmentShipTabs.waves.permission = combinePermissions(fulfillmentWavesTabs);
    }
  
    return values(fulfillmentShipTabs);
  }

  return (
    <div>
      <ParentSpaceProvider>
        <SubspaceProvider {...props} mapState={app.selectors.getItem('getFulfillmentMainTabs')}>
          <MainTabs states={filterAccessibleItems(states)} {...props} />
        </SubspaceProvider>
      </ParentSpaceProvider>
      {children}
    </div>
  );
};

export default MainTabsLayout;
