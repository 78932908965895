import React from 'react';
import { PrintLayout } from '@skunexus/component-catalog';
import MainTabs from './components/PickupFulfillmentMainTabsLayout';

export default app => {
    const tabs = app.pickupFulfillmentTabs.getAll();

    const routers = {
        pickupDetails: {
            path: '/fulfillment-pickup/details/:id',
            title: 'Fulfillments > Pickup > Details',
            permission: ['fulfillments.show'],
            props: () => (
                {
                    printRoute: '/fulfillment-pickup/details/print',
                    detailsPrintPageRoute: '/fulfillment-pickup/details/print',
                    pickingRoute: '/fulfillment-pickup/picking',
                    packingRoute: '/fulfillment-pickup/packing',
                    orderDetailsPageRoute: '/orders',
                    productDetailsRoute: '/products/overview',
                    userDetailsPageRoute: '/users',
                    finalizedRoute: '/fulfillment-pickup/finalized',
                }
            ),
            loader: () => import('@skunexus/pickup-details'),
        },
        pickupDetailsPrint: {
            path: '/fulfillment-pickup/details/print/:id',
            title: 'Fulfillments > Pickup > Details > Print Preview',
            layout: PrintLayout,
            permission: ['fulfillments.show'],
            props: () => (
                {
                    printRoute: '/fulfillment-pickup/details/print',
                    detailsPrintPageRoute: '/fulfillment-pickup/details/print',
                    pickingRoute: '/fulfillment-pickup/picking',
                    orderDetailsPageRoute: '/orders',
                    productDetailsRoute: '/products/overview',
                    userDetailsPageRoute: '/users',
                }
            ),
            loader: () => import('@skunexus/pickup-details'),
        },
        pickupGroupPickDetails: {
            path: '/fulfillment-pickup/group-pick-details/:id',
            title: 'Fulfillments > Pickup > Group Pick Details',
            permission: ['fulfillments.picking'],
            props: () => (
                {
                    printRoute: '/fulfillment-pickup/group-pick-details/print',
                    pickingRoute: '/fulfillment-pickup/group-picking',
                    productDetailsRoute: '/products/overview',
                    userDetailsPageRoute: '/users'
                }
            ),
            loader: () => import('@skunexus/pickup-group-pick-details'),
        },
        pickupGroupPickDetailsPrint: {
            path: '/fulfillment-pickup/group-pick-details/print/:id',
            title: 'Fulfillments > Pickup > Group Pick Details > Print Preview',
            layout: PrintLayout,
            permission: ['fulfillments.picking'],
            props: () => (
                {
                    printRoute: '/fulfillment-pickup/group-pick-details/print',
                    pickingRoute: '/fulfillment-pickup/group-picking',
                    productDetailsRoute: '/products/overview',
                    userDetailsPageRoute: '/users'
                }
            ),
            loader: () => import('@skunexus/pickup-group-pick-details'),
        },
        pickupGroupPicking: {
            path: '/fulfillment-pickup/group-picking/:id',
            title: 'Fulfillments > Pickup > Group Picking',
            permission: ['fulfillments.picking'],
            props: () => (
                {
                    detailsPageRoute: '/fulfillment-pickup/group-pick-details',
                    printPageRoute: '/fulfillment-pickup/group-pick-details/print',
                    packPageRoute: '/fulfillment-pickup/pack',
                    shipPickListRoute: '/fulfillment-pickup/group-pick',
                    productDetailsRoute: '/products/overview',
                    userDetailsPageRoute: '/users',
                    domain: 'inStoreFulfillment'
                }
            ),
            loader: () => import('@skunexus/fulfillment-group-picking'),
        },
        pickupPicking: {
            path: '/fulfillment-pickup/picking/:id',
            title: 'Fulfillments > Pickup > Picking',
            permission: ['fulfillments.picking'],
            props: () => (
                {
                    printPageRoute: '/fulfillment-pickup/details/print',
                    orderDetailsPageRoute: '/orders',
                    detailsPageRoute: '/fulfillment-pickup/details',
                    packPageRoute: '/fulfillment-pickup/packing',
                    shipPickListRoute: '/fulfillment-pickup/pick',
                    productDetailsRoute: '/products/overview',
                    userDetailsPageRoute: '/users',
                    domain: 'inStoreFulfillment'
                }
            ),
            loader: () => import('@skunexus/fulfillment-picking'),
        },
        pickupPacking: {
            path: '/fulfillment-pickup/packing/:id',
            title: 'Fulfillments > Pickup > Packing',
            permission: ['fulfillments.packing'],
            props: () => (
                {
                    productDetailsPageRoute: '/products/overview',
                    orderDetailsPageRoute: '/orders',
                    detailsPageRoute: '/fulfillment-pickup/details',
                    printPageRoute: '/fulfillment-pickup/details/print',
                    packPageRoute: '/fulfillment-pickup/pack',
                    pickPageRoute: '/fulfillment-ship/picking',
                    userDetailsPageRoute: '/users'
                }
            ),
            loader: () => import('@skunexus/pickup-packing'),
        }
    };

    if (tabs.finalized) {
        routers.pickupFinalized = {
            path: tabs.finalized.link,
            title: tabs.finalized.title,
            permission: tabs.finalized.permission,
            props: () => (
                {
                    orderDetailsPageRoute: '/orders',
                    detailsPageRoute: '/fulfillment-pickup/details',
                }
            ),
            loader: () => import('@skunexus/pickup-finalized'),
            render: (Component) => (props) => (
                <MainTabs showCartFilter={false} {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.groupPick) {
        routers.pickupGroupPick = {
            path: tabs.groupPick.link,
            title: tabs.groupPick.title,
            permission: tabs.groupPick.permission,
            props: () => ({
                detailsPageRoute: "/fulfillment-pickup/group-pick-details"
            }),
            loader: () => import('@skunexus/pickup-group-pick'),
            render: (Component) => (props) => (
                <MainTabs showAssignUserFilter={false} {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.main) {
        routers.pickupMain = {
            path: tabs.main.link,
            title: tabs.main.title,
            permission: tabs.main.permission,
            props: () => (
                {
                    shipmentFulfillmentDetailsPageRoute: '/fulfillment-pickup/details',
                    detailsPageRoute: '/fulfillment-pickup/details',
                    pickPageRoute: '/fulfillment-pickup/picking',
                    packPageRoute: '/fulfillment-pickup/packing',
                }
            ),
            loader: () => import('@skunexus/pickup-main'),
            render: (Component) => (props) => (
                <MainTabs
                    showCartFilter={false}
                    showAssignUserFilter={false}
                    {...props}
                >
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.pack) {
        routers.pickupPack = {
            path: tabs.pack.link,
            title: tabs.pack.title,
            permission: tabs.pack.permission,
            props: () => (
                {
                    orderDetailsPageRoute: '/orders',
                    packingPageRoute: '/fulfillment-pickup/packing',
                }
            ),
            loader: () => import('@skunexus/pickup-pack'),
            render: (Component) => (props) => (
                <MainTabs {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.pick) {
        routers.pickupPick = {
            path: tabs.pick.link,
            title: tabs.pick.title,
            permission: tabs.pick.permission,
            props: () => (
                {
                    orderDetailsPageRoute: '/orders',
                    pickPageRoute: '/fulfillment-pickup/picking',
                    groupPageUrl: '/fulfillment-pickup/group-pick-details'
                }
            ),
            loader: () => import('@skunexus/pickup-pick'),
            render: (Component) => (props) => (
                <MainTabs {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.ready) {
        routers.pickupReady = {
            path: tabs.ready.link,
            title: tabs.ready.title,
            permission: tabs.ready.permission,
            props: () => (
                {
                    orderDetailsPageRoute: '/orders',
                    detailsPageRoute: '/fulfillment-pickup/details',
                    printLogsPageRoute: '/fulfillment-pickup/print-logs',
                }
            ),
            loader: () => import('@skunexus/pickup-ready'),
            render: (Component) => (props) => (
                <MainTabs showCartFilter={false} {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }
    if (tabs.hospital) {
        routers.pickupHospital = {
            path: tabs.hospital.link,
            title: tabs.hospital.title,
            permission: tabs.hospital.permission,
            props: () => (
                {
                    orderDetailsPageRoute: '/orders',
                    domain: 'inStoreFulfillment',
                }
            ),
            loader: () => import('@skunexus/fulfillment-hospital'),
            render: (Component) => (props) => (
                <MainTabs {...props}>
                    <Component {...props} />
                </MainTabs>
            ),
        };
    }

    return routers
}