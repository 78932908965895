import React from 'react';
import values from 'lodash/values';
import { ParentSpaceProvider, SubspaceProvider } from 'react-redux-subspace';
import MainTabs from '@skunexus/fulfillment-main-tabs';

import { filterAccessibleItems } from '../../../../utils';
import app from '../../../../config';


const MainTabsLayout = ({ children, ...props }) => {
  const accessibleStates = filterAccessibleItems(values(app.pickupFulfillmentTabs.getAll()));

  return (
    <div>
      <ParentSpaceProvider>
        <SubspaceProvider {...props} mapState={app.selectors.getItem('getFulfillmentMainTabs')}>
          <MainTabs states={accessibleStates} {...props} />
        </SubspaceProvider>
      </ParentSpaceProvider>
      {children}
    </div>
  );
};

export default MainTabsLayout;
