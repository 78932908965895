import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import app from '../config';
import { createRoutes } from '../createRoutes';
import logout from '../routes/logout';

import { filterAccessibleItems, keyedRoutes } from '../utils';
import MainLayout from './MainLayout.js';


export default () => (
  <Switch>
    {createRoutes(filterAccessibleItems(keyedRoutes(app.vendorRoutes.getAll())), MainLayout())}
    {logout()}
    <Redirect path="*" to={`/dashboard`} />
  </Switch>
);
