import values from 'lodash/values';
import { ProductFormModal } from '@skunexus/products';
import { withTabs } from '@skunexus/component-catalog';
import { constants } from '@skunexus/descriptors-form';
import { selectors } from '@skunexus/utils';
import { filterAccessibleItems } from '../../../utils';

export default app => {
    const tabs = app.productDetailsTabs.getAll();

    const routers = {
        products: {
            path: '/products',
            title: 'Products',
            permission: ['products.index'],
            loader: () => import('@skunexus/products'),
            props: () => ({
                detailsPageRoute: '/products/overview',
            }),
        },
        productDetailsStockForm: {
            path: '/products/stock-form/:id',
            title: 'Product Details Add Stock Location',
            permission: 'inventory - stocks.create',
            props: () => (
                {
                    indexPageRoute: '/products/stock',
                    descriptorTypes: constants.descriptorTypes
                }
            ),
            loader: () => import('@skunexus/product-details-stock-form')
        },
        productAdjustmentStockReasons: {
            path: app.settingsGlobalTabs.getItem('adjustment-stock-reasons').link,
            title: app.settingsGlobalTabs.getItem('adjustment-stock-reasons').title,
            permission: app.settingsGlobalTabs.getItem('adjustment-stock-reasons').permission,
            props: () => ({}),
            loader: () => import('@skunexus/product-details-adjustment-stock-reasons'),
            render: Component => (
                withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                    withTabs(filterAccessibleItems(values(app.settingsGlobalTabs.getAll())), { sub: true })(Component)
                )
            ),
        }
    };

    
    if (tabs.overview) {
        routers.productDetailsOverview = {
            path: tabs.overview.link,
            title: tabs.overview.title,
            permission: tabs.overview.permission,
            props: state => (
                {
                    editModal: ProductFormModal,
                    withImages: false,
                    idNameMap: selectors.getIDNameMap(state.app)
                }
            ),
            loader: () => import('@skunexus/product-details'),
            render: Component => {
                return withTabs(filterAccessibleItems(values(app.productDetailsTabs.getAll())))(Component)
            }
        };
    }

    if (tabs.stock) {
        routers.productDetailsStock = {
            path: tabs.stock.link,
            title: tabs.stock.title,
            permission: tabs.stock.permission,
            props: state => (
                {
                    formPageRoute: '/products/stock-form',
                    idNameMap: selectors.getIDNameMap(state.app)
                }
            ),
            loader: () => import('@skunexus/product-details-stock'),
            render: Component => {
                return withTabs(filterAccessibleItems(values(app.productDetailsTabs.getAll())))(Component)
            }
        };
    }
    if (tabs.allocations) {
        routers.productDetailsAllocations = {
            path: tabs.allocations.link,
            title: tabs.allocations.title,
            permission: tabs.allocations.permission,
            props: state => (
                {
                    orderDetailsRoute: '/orders',
                    idNameMap: selectors.getIDNameMap(state.app)
                }
            ),
            loader: () => import('@skunexus/product-details-allocations'),
            render: Component => {
                return withTabs(filterAccessibleItems(values(app.productDetailsTabs.getAll())))(Component)
            }
        };
    }
    if (tabs.adjustments) {
        routers.productDetailsAdjustments = {
            path: tabs.adjustments.link,
            title: tabs.adjustments.title,
            permission: tabs.adjustments.permission,
            props: (state) => (
                {
                    userDetailsRoute: '/settings/users',
                    idNameMap: selectors.getIDNameMap(state.app)
                }
            ),
            loader: () => import('@skunexus/product-details-adjustments'),
            render: Component => {
                return withTabs(filterAccessibleItems(values(app.productDetailsTabs.getAll())))(Component);
            }
        };
    }
    if (tabs.barcodes) {
        routers.productDetailsBarCodes = {
            path: tabs.barcodes.link,
            title: tabs.barcodes.title,
            permission: tabs.barcodes.permission,
            props: state => (
                {
                    idNameMap: selectors.getIDNameMap(state.app)
                }
            ),
            loader: () => import('@skunexus/product-details-barcodes'),
            render: Component => {
                return withTabs(filterAccessibleItems(values(app.productDetailsTabs.getAll())))(Component)
            }
        };
    }
    if (tabs.reservations) {
        routers.productDetailsReservations = {
            path: tabs.reservations.link,
            title: tabs.reservations.title,
            permission: tabs.reservations.permission,
            props: state => (
                {
                    orderDetailsRoute: '/orders',
                    idNameMap: selectors.getIDNameMap(state.app)
                }
            ),
            loader: () => import('@skunexus/product-details-reservations'),
            render: Component => {
                return withTabs(filterAccessibleItems(values(app.productDetailsTabs.getAll())))(Component)
            }
        };
    }
    if (tabs.kitting) {
        routers.productDetailsKitting = {
            path: tabs.kitting.link,
            title: tabs.kitting.title,
            permission: tabs.kitting.permission,
            props: state => (
                {
                    idNameMap: selectors.getIDNameMap(state.app)
                }
            ),
            loader: () => import('@skunexus/product-details-kitting'),
            render: Component => {
                return withTabs(filterAccessibleItems(values(app.productDetailsTabs.getAll())))(Component)
            }
        };
    }
    if (tabs.media) {
        routers.productDetailsMedia = {
            name: 'media',
            path: tabs.media.link,
            title: tabs.media.title,
            permission: tabs.media.permission,
            props: state => ({
                idNameMap: selectors.getIDNameMap(state.app),
                domain: 'product',
                entityName: 'Product',
                basePath: 'products',
            }),
            loader: () => import('@skunexus/media'),
            render: Component => {
                return withTabs(filterAccessibleItems(values(app.productDetailsTabs.getAll())))(Component)
            }
        };
    }

    return routers
}