import createConfigurableObject from '../../../utils/configurationObject';

export default app => {
  app.general = app.general || createConfigurableObject();
  app.general.addItem('name', 'Skunexus');
  app.general.addItem('modules', {
    fulfillmentShipGroupPicking: {
      forceTopDownPicking: true
    }
  });
}
