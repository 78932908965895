import values from 'lodash/values';
import { firstAllowedLink, combinePermissions } from '../../../utils';

export default app => ({
    main: {
        id: 'Dashboard',
        label: 'Dashboard',
        title: 'Fulfillments > Ship',
        link: '/fulfillment-ship/main',
        permission: 'fulfillments.index',
        order: 10,
    },
    pick: {
        id: 'Pick',
        label: 'Pick',
        title: 'Fulfillments > Ship > Pick',
        link: '/fulfillment-ship/pick',
        permission: 'fulfillments.picking',
        order: 20,
    },
    groupPick: {
        id: 'Group Pick',
        label: 'Group Pick',
        title: 'Fulfillments > Ship > Group Pick',
        link: '/fulfillment-ship/group-pick',
        permission: 'fulfillments.picking',
        order: 30,
    },
    waves: {
        id: 'Waves',
        label: 'Waves',
        link: () => firstAllowedLink(values(app.fulfillmentWavesTabs.getAll())),
        permission: () => combinePermissions(values(app.fulfillmentWavesTabs.getAll())),
        order: 40
    },
    pack: {
        id: 'Pack',
        label: 'Pack',
        title: 'Fulfillments > Ship > Pack',
        link: '/fulfillment-ship/pack',
        permission: 'fulfillments.packing',
        order: 50,
    },
    dispatched: {
        id: 'Dispatched',
        label: 'Dispatched',
        title: 'Fulfillments > Ship > Dispatched',
        link: '/fulfillment-ship/dispatched',
        permission: 'fulfillments.index',
        order: 60,
    },
    finalized: {
        id: 'Finalized',
        label: 'Finalized',
        title: 'Fulfillments > Ship > Finalized',
        permission: 'fulfillments.index',
        link: '/fulfillment-ship/finalized',
        order: 70,
    },
    hospital: {
        id: 'Hospital',
        label: 'Hospital',
        title: 'Fulfillments > Ship > Hospital',
        permission: 'fulfillments.hospital',
        link: '/fulfillment-ship/hospital',
        order: 80,
    },
    printLogs: {
        id: 'Print Logs',
        label: 'Print Logs',
        title: 'Fulfillments > Ship > Print Logs',
        link: '/fulfillment-ship/print-logs',
        position: 'right',
        permission: 'fulfillments.print history',
        order: 90,
    }
});