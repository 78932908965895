import UserModel from '../UserModel';
import { createMainLayout } from '../utils';
import values from 'lodash/values';
import app from '../config';

const mainLayoutProps = (vendorId) => (
  () => ({
    userName: app.mainLayout.getItem('userName')(vendorId),
    headerMenuItems: values(app.vendorHeaderMenuItems.getAll()).map(item => typeof item === "function" ? item(vendorId) : item),
    sideMenuItems: values(app.vendorSideMenuItems.getAll())
      .map(item => typeof item === "function" ? item(vendorId) : item)
      .sort(({ order: orderA = 0 }, { order: orderB = 0 }) => orderA - orderB),
  })
);

export default () => {
  return createMainLayout(
    mainLayoutProps(UserModel.getVendorId())
  );
}
