export default app => ({
    label: 'Fulfillments',
    icon: 'orderStatus',
    permission: 'fulfillments.index',
    order: 60,
    items: [
        {
            label: 'Ship',
            link: '/fulfillment-ship/main',
            icon: 'shipment',
        },
        {
            label: 'Pickup',
            link: '/fulfillment-pickup/main',
            icon: 'store',
        },
    ],
})