import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import get from 'lodash/get';

import { CurrentUserModel } from '@skunexus/auth';
import { MainLayout } from '@skunexus/component-catalog';
import { actions as appActions } from '@skunexus/utils';

import app from './config';

export const keyedRoutes = (routes) => Object
  .keys(routes)
  .map(key => (
    {
      name: get(routes[key], 'name', key),
      ...routes[key]
    }
  ));

export const filterAccessibleItems = (items) => value(items)
  .filter(item => {
    let permissions =  value(get(item, 'permission', []));
    if (!Array.isArray(permissions)) {
      permissions = [permissions];
    }
    return !permissions.length || CurrentUserModel.hasAnyPermissions(permissions);
  });

export const combinePermissions = (items) => value(items)
  .reduce((result, item) => {
    let permissions = value(get(item, 'permission', []));
    return result.concat(Array.isArray(permissions) ? permissions : [permissions]);
  }, []);

export const firstAllowedLink = (items) => {
  const item = filterAccessibleItems(items).shift();
  return item !== undefined ? value(item.link) : '';
};

export const value = (item) => item instanceof Function ? item() : item;

export const createMainLayout = (layoutProps) => {
  const mapStateToProps = state => ({
    logoImage: app.general.getItem('logoImage'),
    pageName: app.selectors.getItem('getPageName')(state),
    isSidebarOpened: app.selectors.getItem('getIsSidebarMenuOpen')(state),
    ...value(layoutProps),
  });

  const mapDispatchToProps = disaptch => bindActionCreators({
    toggleSidebar: appActions.toggleSidebarMenu,
  }, disaptch);

  return connect(mapStateToProps, mapDispatchToProps)(
    ({ sideMenuItems, ...props }) => {
      const accessibleItems = filterAccessibleItems(sideMenuItems);
      return <MainLayout {...props} sideMenuItems={accessibleItems} />;
    }
  );
};
