import values from 'lodash/values';
import forEach from 'lodash/forEach';

import setupModules from '../setup';

export default app => {
    //SORT HERE

    forEach(values(setupModules), module => {
        module.setup(app);
    })
}
