export default app => ({
    waves: {
        id: 'Waves',
        label: 'Waves',
        title: 'Fulfillments > Ship > Waves',
        link: '/fulfillment-ship/waves',
        permission: 'fulfillments - waves.index',
        order: 10
    },
    wavesHistory: {
        id: 'Waves History',
        label: 'Waves History',
        title: 'Fulfillments > Ship > Waves History',
        link: '/fulfillment-ship/waves-history',
        permission: 'fulfillments - waves.index',
        order: 20
    }
})