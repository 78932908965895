import React from 'react';
import values from 'lodash/values';
import { PrintLayout, withTabs } from '@skunexus/component-catalog';

import { filterAccessibleItems } from '../../../utils';
import MainTabs from './components/FulfillmentMainTabsLayout';

const openStatus = {
    operator: 'neq', value: 'picked'
};

export default app => ({
    fulfillmentShipMain: {
        path: app.fulfillmentShipTabs.getItem('main').link,
        title: app.fulfillmentShipTabs.getItem('main').title,
        permission: app.fulfillmentShipTabs.getItem('main').permission,
        props: () => (
            {
                shipmentFulfillmentDetailsPageRoute: '/fulfillment-ship/details',
                detailsPageRoute: '/fulfillment-ship/details',
                pickPageRoute: '/fulfillment-ship/picking',
                packPageRoute: '/fulfillment-ship/packing',
                printLogsPageRoute: '/fulfillment-ship/print-logs',
            }
        ),
        loader: () => import('@skunexus/ship-main'),
        render: (Component) => (props) => (
            <MainTabs
                showCartFilter={false}
                showAssignUserFilter={false}
                {...props}
            >
                <Component {...props} />
            </MainTabs>
        ),
    },
    fulfillmentShipPrintLogs: {
        path: app.fulfillmentShipTabs.getItem('printLogs').link,
        title: app.fulfillmentShipTabs.getItem('printLogs').title,
        permission: app.fulfillmentShipTabs.getItem('printLogs').permission,
        props: () => (
            {
                detailsPageRoute: '/fulfillment-ship/details',
                printLogsPageRoute: '/fulfillment-ship/print-logs',
            }
        ),
        loader: () => import('@skunexus/fulfillment-print-logs'),
        render: (Component) => (props) => (
            <MainTabs {...props}>
                <Component {...props} />
            </MainTabs>
        ),
    },
    fulfillmentShipPick: {
        path: app.fulfillmentShipTabs.getItem('pick').link,
        title: app.fulfillmentShipTabs.getItem('pick').title,
        permission: app.fulfillmentShipTabs.getItem('pick').permission,
        props: () => (
            {
                shipmentFulfillmentDetailsPageRoute: '/fulfillment-ship/details',
                detailsPageRoute: '/fulfillment-ship/details',
                pickPageRoute: '/fulfillment-ship/picking',
                packPageRoute: '/fulfillment-ship/packing',
                printLogsPageRoute: '/fulfillment-ship/print-logs',
                groupPageUrl: '/fulfillment-ship/group-picking'
            }
        ),
        loader: () => import('@skunexus/fulfillment-pick'),
        render: (Component) => (props) => (
            <MainTabs
                showCartFilter={false}
                showAssignUserFilter={false}
                {...props}
            >
                <Component {...props} />
            </MainTabs>
        ),
    },
    fulfillmentShipGroupPick: {
        path: app.fulfillmentShipTabs.getItem('groupPick').link,
        title: app.fulfillmentShipTabs.getItem('groupPick').title,
        permission: app.fulfillmentShipTabs.getItem('groupPick').permission,
        props: () => (
            {
                detailsPageRoute: '/fulfillment-ship/group-picking'
            }
        ),
        loader: () => import('@skunexus/fulfillment-group-pick'),
        render: (Component) => (props) => (
            <MainTabs {...props}>
                <Component {...props} />
            </MainTabs>
        ),
    },
    fulfillmentShipPack: {
        path: app.fulfillmentShipTabs.getItem('pack').link,
        title: app.fulfillmentShipTabs.getItem('pack').title,
        permission: app.fulfillmentShipTabs.getItem('pack').permission,
        props: () => (
            {
                orderDetailsPageRoute: '/orders',
                detailsPageRoute: '/fulfillment-ship/packing',
                packingPageRoute: '/fulfillment-ship/packing',
                printLogsPageRoute: '/fulfillment-ship/print-logs',
            }
        ),
        loader: () => import('@skunexus/fulfillment-pack'),
        render: (Component) => (props) => (
            <MainTabs {...props}>
                <Component {...props} onFailedToPurchaseLabel={(fulfillmentId, barcode) => props.history.push(`/fulfillment-ship/packing/${fulfillmentId}?barcode=${barcode}`)} />
            </MainTabs>
        )
    },
    fulfillmentShipDispatched: {
        path: app.fulfillmentShipTabs.getItem('dispatched').link,
        title: app.fulfillmentShipTabs.getItem('dispatched').title,
        permission: app.fulfillmentShipTabs.getItem('dispatched').permission,
        props: () => (
            {
                orderDetailsPageRoute: '/orders',
                detailsPageRoute: '/shipment',
                printLogsPageRoute: '/fulfillment-ship/print-logs',
            }
        ),
        loader: () => import('@skunexus/fulfillment-dispatched'),
        render: (Component) => (props) => (
            <MainTabs showCartFilter={false} {...props}>
                <Component {...props} />
            </MainTabs>
        ),
    },
    fulfillmentShipFulfilled: {
        path: app.fulfillmentShipTabs.getItem('finalized').link,
        title: app.fulfillmentShipTabs.getItem('finalized').title,
        permission: app.fulfillmentShipTabs.getItem('finalized').permission,
        props: () => (
            {
                orderDetailsPageRoute: '/orders',
                detailsPageRoute: '/fulfillment-ship/details',
                printLogsPageRoute: '/fulfillment-ship/print-logs',
            }
        ),
        loader: () => import('@skunexus/fulfillment-fulfilled'),
        render: (Component) => (props) => (
            <MainTabs showCartFilter={false} {...props}>
                <Component {...props} />
            </MainTabs>
        ),
    },
    fulfillmentHospital: {
        path: app.fulfillmentShipTabs.getItem('hospital').link,
        title: app.fulfillmentShipTabs.getItem('hospital').title,
        permission: app.fulfillmentShipTabs.getItem('hospital').permission,
        props: () => (
            {
                orderDetailsPageRoute: '/orders',
            }
        ),
        loader: () => import('@skunexus/fulfillment-hospital'),
        render: (Component) => (props) => (
            <MainTabs {...props}>
                <Component {...props} />
            </MainTabs>
        ),
    },
    fulfillmentWaves: {
        name: 'fulfillmentWaves',
        path: app.fulfillmentWavesTabs.getItem('waves').link,
        title: app.fulfillmentWavesTabs.getItem('waves').title,
        permission: app.fulfillmentWavesTabs.getItem('waves').permission,
        props: () => (
            {
                detailsPageRoute: '/fulfillment-ship/group-pick-details',
                statusOptions: [
                    { value: openStatus, label: 'All' },
                    { value: 'not_picked', label: 'Not Picked' },
                    { value: 'partial_picked', label: 'Partial Picked' },
                ],
                defaultStatus: openStatus,
                gridDomain: 'waves'
            }
        ),
        loader: () => import('@skunexus/fulfillment-waves'),
        render: (Component) => (props) => (
            <MainTabs {...props}>
                {withTabs(filterAccessibleItems(values(app.fulfillmentWavesTabs.getAll())), { sub: true })(Component)(props)}
            </MainTabs>
        ),
    },
    fulfillmentWavesHistory: {
        name: 'fulfillmentWaves',
        path: app.fulfillmentWavesTabs.getItem('wavesHistory').link,
        title: app.fulfillmentWavesTabs.getItem('wavesHistory').title,
        permission: app.fulfillmentWavesTabs.getItem('wavesHistory').permission,
        props: () => (
            {
                detailsPageRoute: '/fulfillment-ship/group-pick-details',
                statusOptions: [],
                defaultStatus: {
                    operator: 'eq', value: 'picked'
                },
                gridDomain: 'waves-history'
            }
        ),
        loader: () => import('@skunexus/fulfillment-waves'),
        render: (Component) => (props) => (
            <MainTabs {...props}>
                {withTabs(filterAccessibleItems(values(app.fulfillmentWavesTabs.getAll())), { sub: true })(Component)(props)}
            </MainTabs>
        ),
    },
    fulfillmentShipDetails: {
        path: '/fulfillment-ship/details/:id',
        title: 'Fulfillments > Ship > Details',
        props: () => (
            {
                printRoute: '/fulfillment-ship/details/print',
                orderDetailsPageRoute: '/orders',
                pickingRoute: '/fulfillment-ship/picking',
                packingRoute: '/fulfillment-ship/packing',
                productDetailsRoute: '/products/overview',
                userDetailsPageRoute: '/users',
                shipmentDetailsPageRoute: '/shipment',
            }
        ),
        permission: ['other.all', 'fulfillments.picking', 'fulfillments.packing'],
        loader: () => import('@skunexus/fulfillment-details'),
    },
    fulfillmentShipDetailsPrint: {
        path: '/fulfillment-ship/details/print/:id',
        title: 'Fulfillments > Ship > Details > Print Preview',
        props: () => (
            {
                detailsPrintPageRoute: '/fulfillment-ship/details/print',
                packingPageRoute: '/fulfillment-ship/packing',
                orderDetailsPageRoute: '/orders',
                productDetailsPageRoute: '/products/overview',
            }
        ),
        layout: PrintLayout,
        permission: ['fulfillments.show'],
        loader: () => import('@skunexus/fulfillment-details'),
    },
    fulfillmentShipPicking: {
        path: '/fulfillment-ship/picking/:id',
        title: 'Fulfillments > Ship > Picking',
        permission: ['fulfillments.picking'],
        props: () => (
            {
                printPageRoute: '/fulfillment-ship/details/print',
                orderDetailsPageRoute: '/orders',
                detailsPageRoute: '/fulfillment-pickup/details',
                packPageRoute: '/fulfillment-ship/packing',
                shipPickListRoute: '/fulfillment-ship/pick',
                productDetailsRoute: '/products/overview',
                userDetailsPageRoute: '/users',
                shipmentDetailsPageRoute: '/shipment',
            }
        ),
        loader: () => import('@skunexus/fulfillment-picking'),
    },
    fulfillmentShipGroupPicking: {
        path: '/fulfillment-ship/group-picking/:id',
        title: 'Fulfillments > Ship > Group Picking',
        permission: ['fulfillments.picking'],
        props: () => (
            {
                detailsPageRoute: '/fulfillment-ship/group-pick-details',
                printPageRoute: '/fulfillment-ship/group-pick-details/print',
                packPageRoute: '/fulfillment-ship/pack',
                shipPickListRoute: '/fulfillment-ship/group-pick',
                productDetailsRoute: '/products/overview',
                userDetailsPageRoute: '/users',
            }
        ),
        loader: () => import('@skunexus/fulfillment-group-picking'),
    },
    fulfillmentShipGroupPickDetails: {
        path: '/fulfillment-ship/group-pick-details/:id',
        title: 'Fulfillments > Ship > Group Pick Details',
        permission: ['fulfillments.picking'],
        props: () => (
            {
                printRoute: '/fulfillment-ship/group-pick-details/print',
                pickingRoute: '/fulfillment-ship/group-picking',
                productDetailsRoute: '/products/overview',
                userDetailsPageRoute: '/users'
            }
        ),
        loader: () => import('@skunexus/fulfillment-group-pick-details'),
    },
    fulfillmentShipGroupPickDetailsPrint: {
        path: '/fulfillment-ship/group-pick-details/print/:id',
        title: 'Fulfillments > Ship > Group Pick Detail > Print Preview',
        layout: PrintLayout,
        permission: ['fulfillments.picking'],
        props: () => (
            {
                printRoute: '/fulfillment-ship/group-pick-details/print',
                pickingRoute: '/fulfillment-ship/group-picking',
                productDetailsRoute: '/products/overview',
                userDetailsPageRoute: '/users'
            }
        ),
        loader: () => import('@skunexus/fulfillment-group-pick-details'),
    },
    fulfillmentShipPacking: {
        path: '/fulfillment-ship/packing/:id',
        title: 'Fulfillments > Ship > Packing',
        permission: ['fulfillments.packing'],
        props: () => (
            {
                productDetailsPageRoute: '/products/overview',
                orderDetailsPageRoute: '/orders',
                detailsPageRoute: '/fulfillment-pickup/packing',
                pickPageRoute: '/fulfillment-ship/picking',
                shipPickListRoute: '/fulfillment-ship/pick',
                shipmentDetailsPageRoute: '/shipment',
            }
        ),
        loader: () => import('@skunexus/fulfillment-packing'),
    },
    fulfillmentDropShipMain: {
        path: '/fulfillment-dropship',
        title: 'Fulfillment Dropship',
        props: () => ({
            orderDetailsPageRoute: '/orders',
            detailsPageRoute: '/fulfillment-ship/details',
            printLogsPageRoute: '/fulfillment-ship/print-logs',
            states: [
                {
                    id: 'pick',
                    label: 'Pick',
                    link: '/fulfillment-ship/pick',
                },
                {
                    id: 'pack',
                    label: 'Pack',
                    link: '/fulfillment-ship/pack',
                },
                {
                    id: 'dispatched',
                    label: 'Dispatched',
                    link: '/fulfillment-ship/dispatched',
                },
                {
                    id: 'fulfilled',
                    label: 'Fulfilled',
                    link: '/fulfillment-ship/fulfilled',
                },
            ]
        }),
        loader: () => import('@skunexus/ship-main'),
    },
    fulfillmentWavesSettings: {
        path: app.settingsFulfillmentTabs.getItem('waves').link,
        title: app.settingsFulfillmentTabs.getItem('waves').label,
        permission: app.settingsFulfillmentTabs.getItem('waves').permission,
        props: () => ({}),
        loader: () => import('@skunexus/waves'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(filterAccessibleItems(values(app.settingsFulfillmentTabs.getAll())), { sub: true })(Component)
            )
        ),
    }
});
