import get from 'lodash/get';
import values from 'lodash/values';
import { selectors } from '@skunexus/utils';
import { withTabs } from '@skunexus/component-catalog';

import UserModel from '../../../UserModel';
import { filterAccessibleItems } from '../../../utils';

export default app => ({
    vendors: {
        path: '/vendors',
        title: 'Vendors',
        permission: ['vendors.index'],
        props: () => (
            {
                detailsPageRoute: get(app.vendorTabs.getAll(), 'overview.link', ''),
                formPageRouteAdd: '/vendors/add',
                formPageRouteEdit: '/vendors/:vendorId/edit',
            }
        ),
        loader: () => import('@skunexus/vendor-grid'),
    },
    vendorFormAdd: {
        name: 'vendorForm',
        path: '/vendors/add',
        title: 'Vendor Form',
        permission: ['vendors.create'],
        props: () => (
            {
                indexPageRoute: '/vendors',
                detailsPageRoute: '/vendors/:vendorId/overview',
            }
        ),
        loader: () => import('@skunexus/vendor-form'),
    },
    vendorFormEdit: {
        name: 'vendorForm',
        path: '/vendors/:id/edit',
        title: 'Vendor Form',
        permission: ['vendors.update'],
        props: state => (
            {
                indexPageRoute: '/vendors',
                detailsPageRoute: '/vendors/:vendorId/overview',
                permissions: {
                    editOnlyAdmin: UserModel.getCurrentUser().hasAnyPermissions('vendors.update'),
                }
            }
        ),
        loader: () => import('@skunexus/vendor-form'),
    },
    vendorContactFormAdd: {
        name: 'vendorContactForm',
        path: '/vendors/:vendorId/contacts/add',
        title: 'Vendor Contact Form',
        permission: ['vendors - contacts.create'],
        props: () => (
            {
                indexPageRoute: '/vendors/:vendorId/contacts',
            }
        ),
        loader: () => import('@skunexus/vendor-contact-form'),
    },
    vendorContactFormEdit: {
        name: 'vendorContactForm',
        path: '/vendors/:vendorId/contacts/:id/edit',
        title: 'Vendor Contact Form',
        permission: ['vendors - contacts.update'],
        props: () => (
            {
                indexPageRoute: '/vendors/:vendorId/contacts',
            }
        ),
        loader: () => import('@skunexus/vendor-contact-form'),
    },
    vendorOverview: {
        path: app.vendorTabs.getItem('overview').link.replace(':vendorId', ':id'), // the only module with different param name
        title: app.vendorTabs.getItem('overview').title,
        permission: app.vendorTabs.getItem('overview').permission,
        props: () => (
            {
                formPageRoute: '/vendors/:vendorId/edit',
                tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(app.vendorTabs.getAll())),
                permissions: {
                    editOnlyAdmin: UserModel.getCurrentUser().hasAllPermissions('vendors.update'),
                    viewAttributes: true,
                    editAttributes: !UserModel.isVendor() && UserModel.getCurrentUser().hasAllPermissions('vendors - custom attributes.value edit'),
                },
                visibleAttributes: UserModel.isVendor()
                    ? (app.general.getItem('modules.vendorOverview.vendorVisibleAttributes')
                        ? app.general.getItem('modules.vendorOverview.vendorVisibleAttributes')
                        : [])
                    : undefined,
            }
        ),
        loader: () => import('@skunexus/vendor-overview')
    },
    vendorProducts: {
        path: app.vendorTabs.getItem('products').link,
        title: app.vendorTabs.getItem('products').title,
        permission: app.vendorTabs.getItem('products').permission,
        props: state => (
            {
                productDetailsPageRoute: '/products/overview',
                tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(app.vendorTabs.getAll())),
                permissions: {
                    create: UserModel.getCurrentUser().hasAnyPermissions('vendors - products.create'),
                    deactive: UserModel.getCurrentUser().hasAnyPermissions('vendors - products.update'),
                    delete: UserModel.getCurrentUser().hasAnyPermissions([
                        'vendors - products.delete',
                        'vendors - products.delete multiple',
                    ])
                },
                canEditPrice: true,
                canEditSku: true,
                idNameMap: selectors.getIDNameMap(state.app)
            }
        ),
        loader: () => import('@skunexus/vendor-product-grid')
    },
    vendorContacts: {
        path: app.vendorTabs.getItem('contacts').link,
        title: app.vendorTabs.getItem('contacts').title,
        permission: app.vendorTabs.getItem('contacts').permission,
        props: state => (
            {
                formAddPageRoute: '/vendors/:vendorId/contacts/add',
                formEditPageRoute: '/vendors/:vendorId/contacts/:id/edit',
                tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(app.vendorTabs.getAll())),
                idNameMap: selectors.getIDNameMap(state.app)
            }
        ),
        loader: () => import('@skunexus/vendor-contact-grid')
    },
    vendorPurchaseOrders: {
        path: app.vendorTabs.getItem('purchaseOrders').link,
        title: app.vendorTabs.getItem('purchaseOrders').title,
        permission: app.vendorTabs.getItem('purchaseOrders').permission,
        props: state => (
            {
                formAddPageRoute: '/purchase-orders/add',
                viewPageRoute: '/purchase-orders/overview/:id',
                purchaseOrderDetailsPageRoute: '/purchase-orders',
                purchaseOrderDetailsPageRouteSuffix: 'overview',
                orderDetailsPageRoute: '/orders',
                tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(app.vendorTabs.getAll())),
                permissions: {
                    create: UserModel.getCurrentUser().hasAnyPermissions('purchase orders.create'),
                    orderOnlyAdmin: UserModel.getCurrentUser().hasAnyPermissions('purchase orders.create'),
                },
                idNameMap: selectors.getIDNameMap(state.app)
            }
        ),
        loader: () => import('@skunexus/vendor-purchase-order-grid')
    },
    vendorReports: {
        path: app.vendorTabs.getItem('reportsAndPayouts').link,
        title: app.vendorTabs.getItem('reportsAndPayouts').title,
        permission: app.vendorTabs.getItem('reportsAndPayouts').permission,
        props: state => (
            {
                tabs: UserModel.isVendor() ? [] : filterAccessibleItems(values(app.vendorTabs.getAll())),
                idNameMap: selectors.getIDNameMap(state.app)
            }
        ),
        loader: () => {
            return import('@skunexus/vendor-reports-payouts').then(ModuleConfig => {
                return {
                    ...ModuleConfig,
                    Module: ModuleConfig.MerchantModule
                };
            });
        }
    },
    vendorDashboardSettings: {
        path: app.settingsVendorTabs.getItem('dashboard').link,
        title: app.settingsVendorTabs.getItem('dashboard').title,
        permission: app.settingsVendorTabs.getItem('dashboard').permission,
        loader: () => import('@skunexus/vendors-daashboard-settings'),
        render: Component => (
            withTabs(filterAccessibleItems(values(app.settingsTabs.getAll())))(
                withTabs(values(app.settingsVendorTabs.getAll()), { sub: true })(Component)
            )
        ),
    },
});