import general from './basic/general';
import reducers from './basic/reducers';
import sagas from './basic/sagas';
import selectors from './basic/selectors';
import mainLayout from './basic/mainLayout';
import headerMenuItems from './basic/headerMenuItems';
import sideMenuItems from './basic/sideMenuItems';
import vendorHeaderMenuItems from './basic/vendorHeaderMenuItems';
import vendorSideMenuItems from './basic/vendorSideMenuItems';
import routes from './basic/routes';
import vendorRoutes from './basic/vendorRoutes';
import settings from './basic/settings';

import products from './modules/products';
import orders from './modules/orders';
import purchaseOrders from './modules/purchaseOrders';
import receiveInventory from './modules/receiveInventory';
import customers from './modules/customers';
import fulfillment from './modules/fulfillment';
import vendors from './modules/vendors';
import integrations from './modules/integrations';
import inventory from './modules/inventory';
import users from './modules/users';
import shipment from './modules/shipment';
import warehouses from './modules/warehouses';
import customAttributes from './modules/customAttributes';
import rma from './modules/rma';

const getCustomSetup = () => {
    try {
        return require('./custom').default;
    } catch (e) {
        return {}
    }
}

export default {
    general,
    reducers,
    sagas,
    selectors,

    routes,
    vendorRoutes,
    mainLayout,
    headerMenuItems,
    sideMenuItems,
    settings,
    vendorHeaderMenuItems,
    vendorSideMenuItems,

    customAttributes,
    products,
    orders,
    purchaseOrders,
    receiveInventory,
    fulfillment,
    customers,
    vendors,
    integrations,
    inventory,
    shipment,
    users,
    warehouses,
    rma,
    
    ...getCustomSetup()
}