export default app => ({
    overview: {
        id: 'Overview',
        label: 'Overview',
        title: 'Vendor Overview',
        link: '/vendors/:vendorId/overview',
        permission: 'vendors.show',
        order: 10,
    },
    products: {
        id: 'Products',
        label: 'Products',
        title: 'Vendor Products',
        link: '/vendors/:vendorId/products',
        permission: 'vendors - products.index',
        order: 20,
    },
    contacts: {
        id: 'Contacts',
        label: 'Contacts',
        title: 'Vendor Contacts',
        link: '/vendors/:vendorId/contacts',
        permission: 'vendors - contacts.index',
        order: 30,
    },
    purchaseOrders: {
        id: 'Purchase Orders',
        label: 'Purchase Orders',
        title: 'Vendor Purchase Orders',
        link: '/vendors/:vendorId/purchase-orders',
        permission: 'purchase orders.index',
        order: 40,
    },
    reportsAndPayouts: {
        id: 'Reports & Payouts',
        label: 'Reports & Payouts',
        title: 'Vendor Reports & Payouts',
        link: '/vendors/:vendorId/reports',
        permission: 'vendors.show',
        order: 50,
    }
})