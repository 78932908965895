import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import App from './App';
import appConfig from './config';

function main() {
  document.title = appConfig.general.getItem('name');
  ReactDOM.render(<App />, document.getElementById('root'));
}

main();
