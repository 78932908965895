const getModuleSetup = () => {
    try {
        return require('./moduleSetup').default;
    } catch (e) {
        return null;
    }
}

export default {
    name: 'customGeneral',
    before: [],
    after: [],
    setup: app => {
        const moduleSetup = getModuleSetup();
        if(moduleSetup && typeof moduleSetup === 'function'){
            moduleSetup(app);
        }
    }
}